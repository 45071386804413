/** @jsxImportSource @emotion/react */
import React, { FC } from 'react';
import { Link, RouteComponentProps, useParams } from '@reach/router';

import { useDataApi } from '../../utils/useDataApi';
import { NotFound } from '../NotFound';
import { format, parseISO } from 'date-fns';
import styled from '@emotion/styled';
import { h1, h2, h3 } from '../../style';
import { useApp } from './useApp';
import { Layout } from '../../components/Layout';
import { Logs } from '../../components/Logs';

export const AppDeployment: FC<RouteComponentProps> = () => {
    let { gitName, gitOwner, deploymentId } = useParams();
    let { data: app, isLoading, error } = useApp({ gitName, gitOwner });
    let [deploymentRes] = useDataApi<DeploymentT>(`/api/v1/deployments/${deploymentId}`);

    if (error) {
        return <div>{error.toString()}</div>;
    }

    if (isLoading || !app) {
        return <div>Loading...</div>;
    }

    if (deploymentRes.isLoading) {
        return <div>Loading...</div>;
    }

    if (deploymentRes.error) {
        return <div>{deploymentRes.error.toString()}</div>;
    }

    let deployment = deploymentRes.data;

    if (deployment.git_name !== app.git_name) {
        // Since we are in app context, and the apove api always get the deployemt. Check that the git_name is correct
        return <NotFound />;
    }

    return (
        <Layout>
            <h1 css={h1}>{app.git_name}</h1>
            <h2 css={[h2, { marginBottom: 16 }]}>
                {deployment.environment}{' '}
                <a
                    href={`https://github.com/${deployment.git_owner}/${deployment.git_name}/commit/${deployment.git_hash}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {deployment.git_ref}@{deployment.git_hash.substring(0, 7)}
                </a>
            </h2>
            <div>Status: {getStatus(deployment)}</div>
            <div>{format(parseISO(deployment.created_at), 'HH:mm dd.MM.yyyy')}</div>
            <div>{deployment.git_message}</div>
            <div css={{ width: 24, height: 24 }} />

            {/* <h3>Config</h3>
            <Code>{JSON.stringify(deployment.config_file, undefined, 4)}</Code> */}

            <h3 css={h3}>
                Runtime Log <Link to="logs">See more</Link>
            </h3>
            <Logs entity={{ type: 'deployment', deploymentId: deployment.id }} />
            <div css={{ width: 24, height: 24 }} />

            {Object.values(deployment.build_pods).map(appling => (
                <>
                    <h3 css={h3}>Build Log ({appling.appling})</h3>
                    {/* If status is success, we know that build logs is saved to db, this is kept forewer. If not we use the one from
            iterapp-builds-server, which is kept for 30 days */}
                    {deployment.status !== 'in-progress' ? (
                        <Code>{appling.build_log}</Code>
                    ) : (
                        <Logs
                            entity={{
                                type: 'build',
                                buildId: deployment.build_id,
                                appling: appling.appling,
                            }}
                        />
                    )}
                </>
            ))}
        </Layout>
    );
};

function getStatus(deployment: DeploymentT): string {
    // if (deployment.build_status !== 'success') {
    //     return `build ${deployment.build_status}`;
    // }

    return deployment.status;
}

const Code = styled.code`
    display: block;
    white-space: pre-wrap;
    border: 1px solid #444;
    padding: 16px;
    max-height: 500px;
    overflow-y: auto;
    max-width: 80%;
`;

export type DeploymentT = {
    id: number;
    build_id: number;
    namespace: string;
    created_at: string;
    status: string;
    environment: string;
    git_owner: string;
    git_name: string;
    git_ref: string;
    git_hash: string;
    git_message?: string;
    // build_status: string;
    config_file: unknown;
    build_pods: Record<
        string,
        {
            id: number;
            status: string;
            appling: string;
            namespace: string;
            name: string;
            build_log: string;
            buildpod_namespace: string;
            buildpod_name: string;
        }
    >;
};
