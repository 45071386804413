import React, { FC, useLayoutEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { codeFont } from '../style';
import { useDataApi } from '../utils/useDataApi';

export const Logs: FC<{
    entity:
        | { type: 'build'; buildId: number; appling: string }
        | { type: 'deployment'; deploymentId: number };
}> = ({ entity }) => {
    let url;

    if (entity.type === 'build') {
        url = `/api/v1/builds/${entity.buildId}/${entity.appling}/logs`;
    } else {
        url = `/api/v1/deployments/${entity.deploymentId}/logs`;
    }

    let [logs] = useDataApi<LogsT>(url);
    let containerRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [containerRef, logs.isLoading]);

    if (logs.isLoading) {
        return <div>Loading...</div>;
    }

    if (logs.error) {
        return <div>{logs.error.toString()}</div>;
    }

    return (
        <LogsContainer ref={containerRef}>
            {logs.data.map(line => (
                <p>{line.log}</p>
            ))}
        </LogsContainer>
    );
};

const LogsContainer = styled.div`
    display: block;
    white-space: pre-wrap;
    border: 1px solid #444;
    padding: 16px;
    max-height: 500px;
    max-width: 80%;
    overflow-y: auto;

    p {
        ${codeFont}
        margin: 8px 0;
    }
`;

type LogsT = Array<{
    time: string;
    log: string;
    app: string;
    pod: string;
    namespace: string;
    container: string;
    host: string;
}>;
