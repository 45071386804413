/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { RouteComponentProps, useParams } from '@reach/router';
import { useState } from 'react';
import { DeploymentLogs } from '../../components/DeploymentLogs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Menu } from '../../components/Menu';

const AppDeploymentLogs: React.FC<RouteComponentProps> = () => {
    const today = new Date();
    today.setHours(6, 0, 0, 0);
    let { deploymentId, gitOwner, gitName } = useParams();
    const [startDate, setStartDate] = useState<Date>(today);
    const [endDate, setEndDate] = useState<Date>(new Date());
    return (
        <div
            css={css`
                color: 'rgb(33,41,47)'};
                background-color:  'rgb(255, 255, 255)'};
                width: 100vw;
                height: 100%;
            `}
        >
            <Menu />
            <div
                css={css`
                    padding: 16px;
                `}
            >
                <DateSelector
                    endDate={endDate}
                    startDate={startDate}
                    setEndDate={setEndDate}
                    setStartDate={setStartDate}
                />
                <DeploymentLogs
                    deploymentId={deploymentId}
                    gitOwner={gitOwner}
                    gitName={gitName}
                    endDate={endDate}
                    startDate={startDate}
                />
            </div>
        </div>
    );
};

export default AppDeploymentLogs;

type DateSelectorProps = {
    endDate: Date;
    startDate: Date;
    setStartDate: (date: Date) => void;
    setEndDate: (date: Date) => void;
};

const DateSelector: React.FC<DateSelectorProps> = ({
    endDate,
    startDate,
    setEndDate,
    setStartDate,
}) => {
    const [openCalendar, setOpenCalender] = useState<boolean>(false);

    const filterFutureTime = (time: number | Date) => {
        const selectedDate = new Date(time);

        if (selectedDate.getDate() === endDate.getDate()) {
            return selectedDate.getTime() < endDate.getTime();
        }
        return true;
    };

    const filterAfterStartTime = (time: number | Date) => {
        const thirtyMinutes = 1800000;
        const currentDate = new Date();
        const selectedDate = new Date(time);

        let afterStartTime = false;

        if (startDate) {
            afterStartTime = selectedDate.getTime() > startDate.getTime();
        }

        return currentDate.getTime() > selectedDate.getTime() - thirtyMinutes && afterStartTime;
    };

    return (
        <div
            css={css`
                display: flex;
                margin-bottom: 20px;
                margin-top: 10px;
                margin-right: 10px;
                width: 500px;
                color: #37373f;
            `}
        >
            <p
                css={css`
                    margin-right: 5px;
                `}
            >
                From:
            </p>
            <DatePicker
                css={css`
                    margin-right: 10px;
                `}
                placeholderText="Enter start date and time"
                selected={startDate}
                onChange={(date: Date) => {
                    setStartDate(date);
                }}
                showTimeSelect
                selectsStart
                timeFormat="HH:mm"
                dateFormat="dd.MM.yyyy H:mm"
                startDate={startDate}
                maxDate={endDate}
                minDate={new Date(new Date().setDate(new Date().getDate()! - 30))}
                filterTime={filterFutureTime}
                onCalendarClose={() => {
                    setOpenCalender(true);
                }}
            />
            <p
                css={css`
                    margin-right: 5px;
                `}
            >
                To:
            </p>
            <DatePicker
                className={'toCalendar'}
                open={openCalendar}
                onClickOutside={() => setOpenCalender(false)}
                onInputClick={() => setOpenCalender(true)}
                placeholderText="Enter end date and time"
                selected={endDate}
                onChange={(date: Date) => {
                    setEndDate(date);
                    setOpenCalender(false);
                }}
                showTimeSelect
                selectsEnd
                timeFormat="HH:mm"
                dateFormat="dd.MM.yyyy H:mm"
                endDate={endDate}
                minDate={startDate}
                maxDate={new Date()}
                filterTime={filterAfterStartTime}
            />
        </div>
    );
};
