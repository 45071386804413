/** @jsxImportSource @emotion/react */

import React, { Suspense } from 'react';
import { Router } from '@reach/router';

import { GlobalStyle } from './style';
import { Apps } from './pages/Apps';
import { Builds } from './pages/Builds';
import { Build } from './pages/Build';
import { NotFound } from './pages/NotFound';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { AppDeployments } from './pages/app/AppDeployments';
import { AppDeployment } from './pages/app/AppDeployment';
import AppDeploymentLogs from './pages/app/AppDeploymentLogs';
import { iterappApi } from './iterappApi';
import { Login } from './components/Login';
import { Settings } from './pages/Settings';

const TestIterappToml = React.lazy(() => import('./pages/TestIterappToml'));
const EncryptSecret = React.lazy(() => import('./pages/EncryptSecret'));

const queryClient = new QueryClient();

const App: React.FC = () => {
    return (
        <>
            <GlobalStyle />
            {/* <Menu /> */}
            <QueryClientProvider client={queryClient}>
                <InnerApp />
            </QueryClientProvider>
        </>
    );
};

type WhoAmI = { t: 'Anonymous' } | { t: 'User'; c: { github_login: string } };

const InnerApp: React.FC = () => {
    const whoami = useQuery(
        'whoami',
        async () => (await iterappApi.get<WhoAmI>('/auth/whoami')).data
    );

    if (whoami.error) {
        return <div>Error: {(whoami.error as Error).toString()}</div>;
    }

    if (whoami.isLoading || !whoami.data) {
        return <div>Loading...</div>;
    }

    if (whoami.data.t === 'Anonymous') {
        return <Login />;
    }
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Router>
                <Apps path="/" />
                <AppDeployments path="/apps/:gitOwner/:gitName" />
                <AppDeployment path="/apps/:gitOwner/:gitName/deployments/:deploymentId" />
                <AppDeploymentLogs path="/apps/:gitOwner/:gitName/deployments/:deploymentId/logs" />
                <Builds path="/builds" />
                <Build path="/builds/:buildId" buildId="" />
                <Build path="/builds/:buildId/:applingName" buildId="" />
                <EncryptSecret path="/encrypt_secret" />
                <TestIterappToml path="/test_iterapp_toml" />
                <Settings path="/settings/*" />
                <NotFound default />
            </Router>
        </Suspense>
    );
};

export default App;
