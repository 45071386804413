import React, { FC } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import { useDataApi } from '../utils/useDataApi';
import { Layout } from '../components/Layout';
import { Logs } from '../components/Logs';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@reach/tabs';
import '@reach/tabs/styles.css';

export const Build: FC<{ buildId: string; applingName?: string } & RouteComponentProps> = ({
    buildId,
    applingName,
}) => {
    let [buildRes] = useDataApi<BuildT>(`/api/v1/builds/${buildId}`);

    if (buildRes.isLoading) {
        return <div>Loading...</div>;
    }

    if (buildRes.error) {
        return <div>{buildRes.error.toString()}</div>;
    }

    let build = buildRes.data;
    let tabIndex = Object.keys(build.build_pods).findIndex(name => name === applingName);
    if (tabIndex === -1) {
        tabIndex = 0;
    }

    return (
        <Layout>
            <div>Build #{build.id}</div>
            <Tabs
                index={tabIndex}
                onChange={newIndex => {
                    console.log({ newIndex });
                    navigate(`/builds/${buildId}/${Object.keys(build.build_pods)[newIndex]}`);
                }}
            >
                <TabList>
                    {Object.keys(build.build_pods).map(name => (
                        <Tab>{name}</Tab>
                    ))}
                </TabList>
                <TabPanels>
                    {Object.values(build.build_pods).map(buildPod => (
                        <TabPanel>
                            <div>{buildPod.status}</div>
                            <div>Created: {build.created_at}</div>
                            <div>Updated {build.updated_at}</div>
                            <div>Created by: {build.created_by}</div>
                            <div>
                                <a
                                    href={`https://github.com/${build.git_owner}/${build.git_name}/tree/${build.git_ref}`}
                                >
                                    {build.git_owner}/{build.git_name}#{build.git_ref}
                                </a>
                            </div>
                            <div>
                                <a
                                    href={`https://github.com/${build.git_owner}/${build.git_name}/commit/${build.git_hash}`}
                                >
                                    {build.git_hash}
                                </a>
                            </div>
                            {buildPod.status === 'success' ? (
                                <div>
                                    <pre>{buildPod.build_log}</pre>
                                </div>
                            ) : (
                                <Logs
                                    entity={{
                                        type: 'build',
                                        buildId: build.id,
                                        appling: buildPod.appling,
                                    }}
                                />
                            )}
                        </TabPanel>
                    ))}
                </TabPanels>
            </Tabs>
        </Layout>
    );
};

type BuildT = {
    id: number;
    updated_at: string;
    created_at: string;
    created_by: string;
    // status: string;
    config_file: unknown;
    git_owner: string;
    git_name: string;
    git_ref: string;
    git_hash: string;
    build_pods: Record<
        string,
        {
            id: number;
            status: string;
            appling: string;
            namespace: string;
            name: string;
            build_log?: string;
        }
    >;
};

export const asd = 123;
