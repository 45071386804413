/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Link, LinkGetProps, RouteComponentProps, Router } from '@reach/router';
import { FC } from 'react';
import * as style from '../style';
import { Layout } from '../components/Layout';
import { iterappApi } from '../iterappApi';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { format } from 'date-fns';

export const Settings: FC<RouteComponentProps> = () => {
    return (
        <Layout>
            <div
                css={css`
                    display: flex;
                    gap: 24px;
                `}
            >
                <SettingsMenu />
                <Router>
                    <Profile path="profile" />
                    <PersonalAccessTokens path="tokens" />
                </Router>
            </div>
        </Layout>
    );
};

const isActive = ({ isCurrent }: LinkGetProps) => {
    return isCurrent ? { 'data-active': 'true' } : {};
};

export const SettingsMenu = () => {
    return (
        <section
            css={css`
                max-width: 300px;
                width: 100%;
            `}
        >
            <h2 css={style.h2}>Menu</h2>
            <Link
                getProps={isActive}
                css={css`
                    ${style.item};
                `}
                to={`profile`}
            >
                Profile
            </Link>
            <Link
                getProps={isActive}
                css={css`
                    ${style.item};
                `}
                to={`tokens`}
            >
                Personal access tokens
            </Link>
        </section>
    );
};

export const Profile: FC<RouteComponentProps> = () => {
    return (
        <div>
            <h1 css={style.h1}>Profile</h1>
            <WhoAmI />
            <LogOut />
        </div>
    );
};

export const WhoAmI = () => {
    type WhoAmI = { t: 'Anonymous' } | { t: 'User'; c: { github_login: string } };
    const whoami = useQuery(
        'whoami',
        async () => (await iterappApi.get<WhoAmI>('/auth/whoami')).data
    );
    if (whoami.error) {
        return <p css={style.p1}>Error fetching profile</p>;
    }
    if (whoami.isLoading || !whoami.data) {
        return <p css={style.p1}>Loading profile...</p>;
    }
    if (whoami.data.t === 'Anonymous') {
        return <p css={style.p1}>Anonymous</p>;
    }
    const github_login = whoami.data.c.github_login;
    return (
        <p css={style.p1}>
            Logged in as GitHub user{' '}
            <a href={'https://github.com/' + github_login}>{github_login}</a>
        </p>
    );
};

export const LogOut = () => {
    const queryClient = useQueryClient();
    const logOut = useMutation(() => iterappApi.get('/auth/logout'), {
        onSuccess: () => {
            queryClient.invalidateQueries('whoami');
        },
    });
    return <button onClick={() => logOut.mutate()}>Log out</button>;
};

type AuthToken = { expire: string; id: string; redacted_token: string };
type AuthTokenWithSecret = { expire: string; id: string; secret: string };

export const PersonalAccessTokens: FC<RouteComponentProps> = () => {
    const queryClient = useQueryClient();

    const tokens = useQuery(
        'auth_tokens',
        async () => (await iterappApi.get<AuthToken[]>('/api/v1/auth/auth_token')).data
    );

    const addToken = useMutation(
        async () => {
            let req = await iterappApi.post<AuthTokenWithSecret>('/api/v1/auth/auth_token');
            return req.data;
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('auth_tokens');
            },
        }
    );

    const deleteToken = useMutation(
        (id: string) => iterappApi.delete(`/api/v1/auth/auth_token/${id}`),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('auth_tokens');
            },
        }
    );

    if (tokens.error) {
        throw tokens.error;
    }

    if (addToken.error) {
        throw addToken.error;
    }

    if (deleteToken.error) {
        throw deleteToken.error;
    }

    if (tokens.isLoading || !tokens.data) {
        return <div>Loading</div>;
    }

    return (
        <div>
            <h1 css={style.h1}>Personal Access Tokens</h1>
            <button onClick={() => addToken.mutate()} disabled={addToken.isLoading}>
                {addToken.isLoading ? 'Generating...' : 'Generate new token'}
            </button>

            {addToken.data && <div>{addToken.data.secret}</div>}

            <table
                css={css`
                    th {
                        text-align: left;
                        font-weight: bold;
                        padding: 6px;
                    }

                    td {
                        padding: 6px;
                    }

                    tr:hover {
                        background: ${style.colors.gray100};
                    }
                `}
            >
                <tr>
                    <th>Token</th>
                    <th>Expire</th>
                    <th></th>
                </tr>
                {tokens.data.map(token => (
                    <tr key={token.id}>
                        <td
                            css={css`
                                font-family: Inconsolata;
                            `}
                        >
                            {token.redacted_token}
                        </td>
                        <td>{format(new Date(token.expire), 'dd.MM.yyyy HH:mm')}</td>
                        <td>
                            <button onClick={() => deleteToken.mutate(token.id)}>Delete</button>
                        </td>
                    </tr>
                ))}
            </table>
        </div>
    );
};
